import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
  useNavigate,
} from 'react-router-dom';

import { AppLayout } from './pages/AppLayout';
import { DashboardLayout } from './pages/app/DashboardLayout';
import { SettingsPage } from './pages/app/account/AccountPage';
import { DataAndPrivacyPage } from './pages/app/account/DataAndPrivacyPage';
import { PrivacyPolicyPage } from './pages/app/account/PrivacyPolicyPage';
import { ProfilePage } from './pages/app/account/ProfilePage';
import { RewardsPage } from './pages/app/rewards/RewardsPage';
import { TransactionsPointsAddedPage } from './pages/app/transactions/TransactionsPointsAddedPage';
import { TransactionsPointsClaimedPage } from './pages/app/transactions/TransactionsPointsClaimedPage';
import { TransactionsLayout } from './pages/app/transactions/TransactionsRouter';
import { AuthLoginPage } from './pages/auth/AuthLoginPage';
import { useIsBuyerUserProfileIncomplete } from './services/buyersService';
import { useAuth } from './utils/useAuth';

export const RedirectToLoginIfUnauthenticated = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to={'/auth/login'} replace />;
  }
  return children;
};

export const RedirectToDashboardIfAuthenticated = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to={'/'} replace />;
  }
  return children;
};

/**
 * Only redirect to profile page if profile is incomplete on first load
 */
export const RedirectToProfilePageIfProfileIncomplete = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const isProfileIncomplete: boolean = useIsBuyerUserProfileIncomplete();

  useEffect(() => {
    if (isProfileIncomplete) {
      navigate('/account/profile');
    }
  }, []); // Don't add dependencies to avoid infinite re-renders

  return children;
};

export const routes = createRoutesFromElements(
  <Route element={<AppLayout />}>
    <Route
      path={'/'}
      element={
        <RedirectToLoginIfUnauthenticated>
          <DashboardLayout />
        </RedirectToLoginIfUnauthenticated>
      }>
      <Route
        element={
          <RedirectToProfilePageIfProfileIncomplete>
            <Outlet />
          </RedirectToProfilePageIfProfileIncomplete>
        }>
        <Route index element={<Navigate to="/rewards" replace />} />
        <Route path={'/rewards'} element={<RewardsPage />} />
        <Route path={'/transactions'} element={<TransactionsLayout />}>
          <Route
            index
            element={<Navigate to="/transactions/added" replace />}
          />
          <Route
            path={'/transactions/added'}
            element={<TransactionsPointsAddedPage />}
          />
          <Route
            path={'/transactions/claimed'}
            element={<TransactionsPointsClaimedPage />}
          />
        </Route>
        <Route path={'/account'}>
          <Route index element={<SettingsPage />} />
          <Route path={'/account/profile'} element={<ProfilePage />} />
          <Route
            path={'/account/privacy-policy'}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={'/account/data-privacy'}
            element={<DataAndPrivacyPage />}
          />
        </Route>
      </Route>
    </Route>
    <Route
      path={'/auth/login'}
      element={
        <RedirectToDashboardIfAuthenticated>
          <AuthLoginPage />
        </RedirectToDashboardIfAuthenticated>
      }
    />
  </Route>,
);
