import _ from 'lodash';
import { DateTime } from 'luxon';
import { Box, Divider, HStack, Text } from 'native-base';
import React, { useMemo } from 'react';

import { Rewards } from '@waffle/common/src/models';

import { useRewardsPointTransactionsQuery } from '../../../services/rewardsService';

export const TransactionsPointsAddedPage = () => {
  const { data: rewardsPointTransactions } = useRewardsPointTransactionsQuery();

  // Sort and filter out the ADDED rewardsPointTransactions
  // TODO: Filter on server side
  const rewardsPointTransactionsAdded: Rewards.PointTransaction[] | undefined =
    useMemo(() => {
      return rewardsPointTransactions
        .filter(
          (rewardsPointTransaction) =>
            rewardsPointTransaction.pointsAwarded > 0,
        )
        .sort((rewardsPointTransaction1, rewardsPointTransaction2) =>
          rewardsPointTransaction1.createdAt <
          rewardsPointTransaction2.createdAt
            ? 1
            : -1,
        );
    }, [rewardsPointTransactions]);

  // group the added rewardsPointTransactions by their date
  const rewardsPointTransactionsByDate: {
    [date: string]: Rewards.PointTransaction[];
  } = _.groupBy(rewardsPointTransactionsAdded, (rpt) =>
    DateTime.fromISO(rpt.createdAt).toISODate(),
  );

  return (
    <Box width={'container'}>
      {Object.entries(rewardsPointTransactionsByDate).map(
        ([date, rewardsPointTransactions]: [
          string,
          Rewards.PointTransaction[],
        ]) => (
          <Box key={date}>
            <Box background={'background.200'}>
              <Text padding={'2'}>
                {`${DateTime.fromISO(date).weekdayLong}, ` +
                  `${DateTime.fromISO(date).toFormat('dd LLLL yyyy')}`}
              </Text>
            </Box>
            <Box>
              {rewardsPointTransactions.map((rewardsPointTransaction) => (
                <Box key={rewardsPointTransaction.id}>
                  <HStack justifyContent={'space-between'}>
                    <Text padding={'3'}>
                      {DateTime.fromISO(
                        rewardsPointTransaction.createdAt,
                      ).toFormat('hh:mm a')}
                    </Text>
                    <Text
                      color={'primary.600'}
                      fontWeight={'semibold'}
                      padding={'3'}>
                      +{rewardsPointTransaction.pointsAwarded}
                    </Text>
                  </HStack>
                  <Divider thickness={'2'} />
                </Box>
              ))}
            </Box>
          </Box>
        ),
      )}
    </Box>
  );
};
