import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import {
  Box,
  Button,
  HBox,
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
  Link,
  Spinner,
  Text,
  TextInput,
  useToast,
} from '@waffle/ui-web';

import { WaffleCountryCallingCodePicker } from '../../components/WaffleCountryCallingCodePicker';
import { useSubdomainSellerQuery } from '../../services/sellersService';
import buyersApiClient from '../../utils/ApiService';

const OTP_REGEX: string = '^\\d+$';
const RESEND_OTP_TIMEOUT_SECONDS: number = 120;

const useGenerateOtpMutation = () => {
  return useMutation({
    mutationFn: async ({ mobileNumber }: { mobileNumber: string }) => {
      return await buyersApiClient.request({
        method: 'POST',
        url: `/auth/otp/generate`,
        data: { mobileNumber: mobileNumber },
      });
    },
  });
};

const useVerifyOtpMutation = () => {
  return useMutation({
    mutationFn: async ({
      otp,
      mobileNumber,
    }: {
      otp: string;
      mobileNumber: string;
    }) => {
      return await buyersApiClient.verifyOtp({
        otp: otp,
        mobileNumber: mobileNumber,
      });
    },
  });
};

export const AuthLoginPage = () => {
  const toast = useToast();

  const { data: seller } = useSubdomainSellerQuery();
  const { mutate: generateOtp, isPending: isGenerateOtpPending } =
    useGenerateOtpMutation();

  const [countryCode, setCountryCode] = useState<CountryUtil.CountryCode>(
    seller.countryCode,
  );
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');
  const [showAuthOTPVerficationCard, setShowAuthOTPVerficationCard] =
    useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [timerSeconds, setTimerSeconds] = useState(0);

  /**
   * Hook to handle timer countdown for OTP resend
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (timerSeconds <= 0) {
        clearInterval(interval);
        return;
      }

      setTimerSeconds(timerSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerSeconds]);

  const handleContinue = () => {
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Failed to send OTP',
        description: `Your mobile number is invalid. Please enter a valid ${CountryUtil.getCountryConfig(countryCode).name} mobile number.`,
      });
    }
    generateOtp(
      { mobileNumber: mobileNumber },
      {
        onSuccess: () => {
          setShowAuthOTPVerficationCard(true);

          // reset timer
          setTimerSeconds(RESEND_OTP_TIMEOUT_SECONDS);
        },
        onError: () => {
          setShowAuthOTPVerficationCard(false);
          toast.show({
            status: 'error',
            title: 'Failed to send OTP',
            description: 'Please wait a while and try again later!',
          });
        },
      },
    );
  };

  const callingCode: PhoneUtil.CallingCode =
    PhoneUtil.getCallingCode(countryCode);

  const mobileNumber: string = `${callingCode}${mobileNumberInput}`;

  return (
    <Box className={'flex-1 items-center justify-center bg-gray-100'}>
      {showAuthOTPVerficationCard ? (
        <OtpVerificationCard
          mobileNumber={mobileNumber}
          otp={otp}
          setOtp={setOtp}
          timerSeconds={timerSeconds}
          resetTimer={() => setTimerSeconds(RESEND_OTP_TIMEOUT_SECONDS)}
        />
      ) : (
        <Box className={'w-[480px] max-w-full rounded-3xl bg-white'}>
          {!!seller.logoImage ? (
            <Box className={'items-center justify-center'}>
              <Box
                className={
                  'bg-background mt-[-64px] h-[128px] w-[128px] rounded-lg p-2'
                }>
                <img
                  className={'h-full w-full rounded-md object-contain'}
                  src={seller.logoImage.thumbnailUrl}
                  alt={'Seller Logo'}
                />
              </Box>
            </Box>
          ) : null}

          <Box className={'gap-6 p-8'}>
            <Text variant={'h1'} className={'text-center'}>
              {seller.name}
            </Text>

            <Box className={'gap-2'}>
              <TextInput
                value={mobileNumberInput}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (!PhoneUtil.isValidMobileNumberInput(newValue)) {
                    return;
                  }
                  setMobileNumberInput(newValue);
                }}
                autoFocus
                type="tel"
                placeholder="Enter Mobile Number"
                leftElement={
                  <WaffleCountryCallingCodePicker
                    countryCode={countryCode}
                    onCountryCodeChange={setCountryCode}
                  />
                }
              />

              <Button
                onPress={handleContinue}
                isDisabled={
                  !PhoneUtil.isValidMobileNumber({
                    text: mobileNumber,
                    countryCode: countryCode,
                  })
                }
                isLoading={isGenerateOtpPending}
                size={'lg'}>
                Continue
              </Button>
            </Box>
            <Box>
              <Text variant={'muted'}>
                {`By continuing, you agree to the `}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.wafflepos.com/legal/waffle-buyer-privacy-policy">
                  Waffle-Buyer Privacy Policy
                </Link>
                .
              </Text>
            </Box>
          </Box>
        </Box>
      )}
      <Link
        href={`https://www.wafflepos.com/`}
        shouldOpenInNewTab
        className={
          'fixed bottom-6 left-0 right-0 flex items-center justify-center gap-2'
        }>
        <img src={'/waffle-logo-gray600-128.png'} className={'h-[16px]'} />
        <Text className={'text-sm italic text-gray-600'}>
          Powered by Waffle
        </Text>
      </Link>
    </Box>
  );
};

const OtpVerificationCard = ({
  mobileNumber,
  otp,
  setOtp,
  timerSeconds,
  resetTimer,
}: {
  mobileNumber: string;
  otp: string;
  setOtp: (otp: string) => void;
  timerSeconds: number;
  resetTimer: () => void;
}) => {
  const toast = useToast();

  const { mutate: generateOtp, isPending: isGenerateOtpPending } =
    useGenerateOtpMutation();
  const { mutate: verifyOtp, isPending: isVerifyOtpPending } =
    useVerifyOtpMutation();

  const handleVerifyOtp = () => {
    verifyOtp(
      { otp: otp, mobileNumber: mobileNumber },
      {
        onError: (err: any) => {
          const status = err.response.status;
          let errMessage = '';

          switch (status) {
            case 401: {
              errMessage = 'Invalid OTP, please try again';
              break;
            }
            case 429: {
              errMessage =
                'OTP Verification Attempts Exceeded, please get new OTP';
              break;
            }
            case 400: {
              errMessage = 'OTP Expired, please get new OTP';
              break;
            }
            case 410: {
              errMessage = 'OTP Expired, please get new OTP';
              break;
            }
            default: {
              errMessage = 'Invalid OTP, please try again';
              break;
            }
          }
          setOtp('');
          toast.show({
            status: 'error',
            title: 'Failed to verify OTP',
            description: errMessage,
          });
        },
      },
    );
  };

  const handleResendOtp = () => {
    generateOtp(
      { mobileNumber: mobileNumber },
      {
        onSuccess: () => {
          resetTimer();
        },
        onError: () => {
          toast.show({
            status: 'error',
            title: 'Failed to send OTP',
            description: 'Please wait a while and try again later!',
          });
        },
      },
    );
  };

  const isResendOtpEnabled = timerSeconds === 0;

  return (
    <Box className={'w-[480px] max-w-full gap-8 rounded-3xl bg-white p-8'}>
      <Box>
        <Text variant={'h3'}>{`Enter the OTP sent to your mobile number`}</Text>
        <Text variant={'muted'}>{`We've sent the OTP to ${mobileNumber}`}</Text>
      </Box>

      <Box className={'items-center'}>
        {isVerifyOtpPending ? (
          <Spinner />
        ) : (
          <InputOTP
            maxLength={6}
            pattern={OTP_REGEX}
            value={otp}
            onChange={setOtp}
            onComplete={handleVerifyOtp}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        )}
      </Box>

      <HBox className={'items-center justify-center gap-2'}>
        <Text variant={'muted'}>Didn't receive the code?</Text>
        <Button
          variant={'link'}
          size={'none'}
          onPress={handleResendOtp}
          isDisabled={!isResendOtpEnabled}
          isLoading={isGenerateOtpPending}>
          {isResendOtpEnabled
            ? 'Resend OTP'
            : `Resend OTP in ${Math.floor(timerSeconds / 60)}:${(
                timerSeconds % 60
              )
                .toString()
                .padStart(2, '0')}`}
        </Button>
      </HBox>
    </Box>
  );
};
