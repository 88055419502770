import { Box, ChevronRightIcon, HStack, Pressable, Text } from 'native-base';
import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useIsBuyerUserProfileIncomplete } from '../../../services/buyersService';

const exclamationColor = '#f9c044';

export const SettingsPage = () => {
  const navigate = useNavigate();
  const isBuyerUserProfileIncomplete = useIsBuyerUserProfileIncomplete();

  return (
    <Box alignItems={'center'}>
      <Box width={'100%'} maxWidth={'640px'}>
        <Box marginBottom={4}>
          <Text variant={'header'}>Account</Text>
        </Box>

        <Box
          backgroundColor={'background.0'}
          padding={4}
          borderRadius={'8'}
          marginBottom={8}>
          <Box>
            <Pressable
              onPress={() => {
                navigate('/account/profile');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'8px'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <HStack alignItems={'center'}>
                <Text variant={'label'} marginRight={1}>
                  Profile
                </Text>
                {isBuyerUserProfileIncomplete ? (
                  <FaExclamationCircle color={exclamationColor} />
                ) : null}
              </HStack>
              <ChevronRightIcon size={4} />
            </Pressable>

            <Pressable
              onPress={() => {
                navigate('/account/data-privacy');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <Text variant={'label'}>Data & Privacy</Text>
              <ChevronRightIcon size={4} />
            </Pressable>
            <Pressable
              onPress={() => {
                navigate('/account/privacy-policy');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <Text variant={'label'}>Privacy Policy</Text>
              <ChevronRightIcon size={4} />
            </Pressable>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
