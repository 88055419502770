import { DateTime } from 'luxon';
import { Box, Divider, Text } from 'native-base';
import React from 'react';

import { useBuyerUserQuery } from '../../../services/buyersService';
import { useCustomerQuery } from '../../../services/customersService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';

export const PrivacyPolicyPage = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const { data: buyerUser } = useBuyerUserQuery();
  const { data: customer } = useCustomerQuery();

  return (
    <Box alignItems={'center'}>
      <Box width={'100%'} padding={4} maxWidth={'600px'}>
        <Text variant={'header'}>Privacy Policy</Text>
        <Text>Important information about how we may use your data</Text>
        <Divider marginY={4} />
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingRight={8}
          marginY={4}>
          <Box>
            <Text variant={'label'}>Waffle</Text>
            <Text variant={'subText'}>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://www.wafflepos.com/legal/waffle-buyer-privacy-policy'
                }
                style={{ color: 'inherit' }}>
                Privacy Policy
              </a>
            </Text>
          </Box>
          <Box>
            {!!buyerUser.acceptedWafflePrivacyPolicyAt ? (
              <>
                <Text variant={'subText'}>Accepted on</Text>
                <Text>
                  {DateTime.fromISO(
                    buyerUser.acceptedWafflePrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              </>
            ) : null}
          </Box>
        </Box>
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingRight={8}
          marginY={4}>
          <Box>
            <Text variant={'label'}>{seller.name}</Text>
            <Text variant={'subText'}>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  seller.latestSellerPrivacyPolicy?.file?.url ??
                  `https://www.wafflepos.com/legal/seller-buyer-privacy-policy?seller=${seller.slug}`
                }
                style={{ color: 'inherit' }}>
                Privacy Policy
              </a>
            </Text>
          </Box>
          <Box>
            {!!customer.acceptedSellerPrivacyPolicyAt ? (
              <>
                <Text variant={'subText'}>Accepted on</Text>
                <Text>
                  {DateTime.fromISO(
                    customer.acceptedSellerPrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
