/**
 * Input component from shadcn/ui
 * We use this as a base component to extend from (eg. image input, number input) most of the time you should be using <TextInput> instead
 *
 * Edits:
 * 1. Add isLoading prop
 * 2. Add leftElement and rightElement props
 */
import * as React from 'react';

import { cn } from '../../lib/utils';
import { Spinner } from './spinner';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isLoading?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, leftElement, rightElement, isLoading, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          'border-input bg-background focus-within:border-ring ring-offset-background focus-within:ring-ring flex h-10 w-full items-center gap-1 rounded-sm border transition-all focus-within:ring-2 focus-within:ring-offset-2',
          className,
        )}>
        {!!leftElement && (
          <div className="flex shrink-0 items-center">{leftElement}</div>
        )}
        <input
          type={type}
          className={cn(
            'placeholder:text-muted-foreground w-full bg-transparent text-base file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
          )}
          ref={ref}
          {...props}
        />
        {isLoading && (
          <div className="flex shrink-0 items-center">
            <Spinner className="text-muted-foreground h-4 w-4" />
          </div>
        )}
        {!!rightElement && (
          <div className="flex shrink-0 items-center">{rightElement}</div>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
