import React, { useState } from 'react';

import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import {
  Button,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  HBox,
  Text,
} from '@waffle/ui-web';
import { ChevronDownIcon } from '@waffle/ui-web/icons';

export const WaffleCountryCallingCodePicker = ({
  countryCode,
  onCountryCodeChange,
}: {
  countryCode: CountryUtil.CountryCode;
  onCountryCodeChange: (countryCode: CountryUtil.CountryCode) => void;
}) => {
  const [open, setOpen] = useState(false);

  const countryFlagIcon = CountryUtil.getCountryConfig(countryCode).flagIcon;
  const callingCode: PhoneUtil.CallingCode =
    PhoneUtil.getCallingCode(countryCode);

  return (
    <>
      <Button
        variant={'unstyled'}
        size={'sm'}
        onPress={() => setOpen(true)}
        className={'flex items-center gap-2'}>
        <Text>{countryFlagIcon}</Text>
        <Text variant={'muted'}>{callingCode}</Text>
        <ChevronDownIcon className="size-4 opacity-50" />
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Search country or calling code" />
        <CommandList>
          <CommandEmpty>No country found.</CommandEmpty>
          <CommandGroup className="max-h-[300px] overflow-auto">
            {CountryUtil.getCountryConfigMap().map((countryConfig) => (
              <CommandItem
                key={countryConfig.code}
                value={`${countryConfig.name} ${countryConfig.callingCode}`}
                onSelect={() => {
                  onCountryCodeChange(countryConfig.code);
                  setOpen(false);
                }}>
                <HBox className="w-full items-center justify-between">
                  <HBox className="items-center gap-2">
                    <Text>{countryConfig.flagIcon}</Text>
                    <Text variant={'label'}>{countryConfig.name}</Text>
                  </HBox>
                  <Text variant={'muted'}>{countryConfig.callingCode}</Text>
                </HBox>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
};
