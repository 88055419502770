import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Box,
  Container,
  HBox,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  Spinner,
  Text,
  WaffleErrorBoundary,
  WaffleSuspenseBoundary,
  useSidebar,
} from '@waffle/ui-web';
import {
  ArrowLeftRightIcon,
  CircleAlertIcon,
  GiftIcon,
  LogOutIcon,
  StoreIcon,
  UserIcon,
} from '@waffle/ui-web/icons';

import { useIsBuyerUserProfileIncomplete } from '../../services/buyersService';
import { useCustomerQuery } from '../../services/customersService';
import { useSubdomainSellerQuery } from '../../services/sellersService';
import buyersApiClient from '../../utils/ApiService';
import { useAuth } from '../../utils/useAuth';
import PrivacyConsentPage from './privacyConsent/PrivacyConsentPage';

export const DashboardLayout = () => {
  const { data: customer } = useCustomerQuery();

  // No matter what, the Buyer needs to accept the Seller's privacy policy to continue to access the dashboard
  if (!customer || !customer.acceptedSellerPrivacyPolicyAt) {
    return <PrivacyConsentPage />;
  }

  return (
    <SidebarProvider>
      <NavBar />
      <main className={'w-full bg-gray-50'}>
        <WaffleErrorBoundary>
          <WaffleSuspenseBoundary>
            <TopNav />
            <Container className={'py-4'}>
              <Outlet />
            </Container>
          </WaffleSuspenseBoundary>
        </WaffleErrorBoundary>
      </main>
    </SidebarProvider>
  );
};

const NavBar = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const { refreshToken, setAccessToken, setRefreshToken } = useAuth();

  const { data: seller } = useSubdomainSellerQuery();
  const isBuyerUserProfileIncomplete: boolean =
    useIsBuyerUserProfileIncomplete();

  const { mutate: logOut, isPending: isLogOutPending } = useMutation({
    mutationFn: async ({ refreshToken }: { refreshToken: string }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: '/auth/revoke',
        data: {
          refreshToken: refreshToken,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.removeQueries();
      setAccessToken(undefined);
      setRefreshToken(undefined);
    },
  });

  return (
    <Sidebar className={'border-none'} variant={'inset'} collapsible={'icon'}>
      <SidebarHeader className={'p-4'}>
        <HBox className="gap-2">
          <Avatar className="h-[40px] w-[40px] rounded-md">
            <AvatarImage
              src={seller.logoImage?.thumbnailUrl}
              alt="Seller Logo"
            />
            <AvatarFallback className="rounded-lg">
              <StoreIcon />
            </AvatarFallback>
          </Avatar>

          <Text variant={'label'}>{seller.name}</Text>
        </HBox>
      </SidebarHeader>
      <SidebarContent className={'p-4'}>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={'py-6'}
              isActive={location.pathname.startsWith('/rewards')}>
              <Link to={'/rewards'}>
                <GiftIcon className={'size-6'} />
                <Text variant={'h3'}>Rewards</Text>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={'py-6'}
              isActive={location.pathname.startsWith('/transactions')}>
              <Link to={'/transactions'}>
                <ArrowLeftRightIcon className={'size-6'} />
                <Text variant={'h3'}>Transactions</Text>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter className={'p-4'}>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={'py-6'}
              isActive={location.pathname.startsWith('/account')}>
              <Link to={'/account'}>
                <UserIcon className={'size-6'} />
                <Text variant={'h3'}>Account</Text>
                <SidebarMenuBadge>
                  {isBuyerUserProfileIncomplete ? (
                    <CircleAlertIcon className={'size-6'} />
                  ) : null}
                </SidebarMenuBadge>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton
              className={'py-6'}
              onClick={() => {
                if (!!refreshToken) {
                  logOut({ refreshToken });
                }
              }}>
              {isLogOutPending ? (
                <Spinner className={'size-6'} />
              ) : (
                <LogOutIcon className={'size-6'} />
              )}
              <Text variant={'h3'}>Log Out</Text>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
};

const TopNav = () => {
  const { isMobile } = useSidebar();

  if (!isMobile) {
    return null;
  }

  return (
    <nav className={'bg-background sticky top-0 z-50 h-[48px] w-full'}>
      <Container className="h-full w-full flex-row items-center gap-2">
        <SidebarTrigger />

        {/* Spacer component */}
        <Box className={'flex-1'} />
      </Container>
    </nav>
  );
};
